// native
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// fa icons
import { FaIconLibrary, FaConfig, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// trackJS
import { TrackJS } from 'trackjs';

// component
import { AppComponent } from './app.component';

// service
import { AppHttpInterceptor } from './app-http-interceptor.service';

// module
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { ListModule } from './list/list.module';
import { NotFoundModule } from './notfound/notfound.module';

// icons
import { APP_ICONS } from './icons/icons';

import { environment } from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    CoreModule.forRoot(),
    ListModule,
    NotFoundModule,
    FontAwesomeModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor(
    faConfig: FaConfig,
    faIconLibrary: FaIconLibrary
  ) {
    faIconLibrary.addIcons(...APP_ICONS);

    TrackJS.install({
      token: environment.trackJsToken,
      application: environment.trackJsApp,
      enabled: environment.trackJsEnabled
    });
  }
}