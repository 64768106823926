<div class="text--center">
  <br>
  <br>
  This list is no longer being shared.
  <br>
  <br>
  If you think this is an error, contact <a [href]="linkUrl">support&#64;papersapp.com</a> for assistance.
  <br>
  <br>
  <canvas id="canvas" width="400" height="300"></canvas>
</div>